function isNonEmptyArray(collection) {
  return Array.isArray(collection) && collection.length > 0;
}
export function containsStrings(collection) {
  return Array.isArray(collection) && collection.some(value => typeof value === "string");
}
export function containsDates(collection) {
  return Array.isArray(collection) && collection.some(value => value instanceof Date);
}
export function containsNumbers(collection) {
  return Array.isArray(collection) && collection.some(value => typeof value === "number");
}
export function containsOnlyStrings(collection) {
  return isNonEmptyArray(collection) && collection.every(value => typeof value === "string");
}

/**
 * Creates an array of array values not included in the other given arrays
 * @param a The array to inspect
 * @param b The values to exclude
 * @returns The new array of filtered values
 */
export function difference(a, b) {
  if (a && b) {
    return a.filter(value => !b.includes(value));
  }
  return [];
}
export function isArrayOfArrays(collection) {
  return isNonEmptyArray(collection) && collection.every(Array.isArray);
}
export function removeUndefined(arr) {
  return arr.filter(el => el !== undefined);
}
export function getMaxValue(arr) {
  for (var _len = arguments.length, values = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    values[_key - 1] = arguments[_key];
  }
  const array = arr.concat(values);
  return containsDates(array) ? new Date(Math.max(...array)) // Dates will be coerced to numbers
  : Math.max(...array);
}
export function getMinValue(arr) {
  for (var _len2 = arguments.length, values = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    values[_key2 - 1] = arguments[_key2];
  }
  const array = arr.concat(values);
  return containsDates(array) ? new Date(Math.min(...array)) // Dates will be coerced to numbers
  : Math.min(...array);
}